<template>
  <div class="content-wrapper overflow-visible">  
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />
    <div class="content">
      <validation-observer ref="VFormBBL">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="mb-0 d-flex flex-column">
              <div class="form-underlying card-body">
                <div class="wrap_line">
                  <h3> <span>Identitas</span></h3>
                  <div class="mb-3">
                    <table class="table table-bordered">
                      <tr>
                        <td width="20%">
                          <div class="result_tab">
                            <h4>Nama Bayi</h4>
                            <p>{{row.ap_fullname||"-"}}</p>
                          </div>
                        </td>
                        <td width="20%">
                          <div class="result_tab">
                            <h4>Tanggal Lahir</h4>
                            <p>{{row.ap_dob | moment("DD MMMM YYYY")}}</p>
                          </div>
                        </td>
                        <td width="20%">
                          <div class="result_tab">
                            <h4>Jenis Kelamin</h4>
                            <p>{{getConfigDynamic(Config.mr.StatusKelamin,row.ap_gender)||"-"}}</p>
                          </div>
                        </td>
                        <td width="20%">
                          <div class="result_tab">
                            <h4>Nama Ayah</h4>
                            <p>{{row.arpdo_ayah_nama||"-"}}</p>
                          </div>
                        </td>
                        <td width="20%">
                          <div class="result_tab">
                            <h4>Anak Ke- <strong class="text-danger">*</strong></h4>
                            <b-form-input @input="onInput($event, 'anak-ke')" @blur="onBlur($event, 'anak-ke')" @focus="onFocus($event, 'anak-ke')" :disabled="disabled['anak-ke']" v-model="row.arankaabl_anak_ke" :formatter="numberOnly" type="text"
                              class="form-control" />
                            <p class='text-muted' v-if="disabled['anak-ke']">User lain sedang mengisi...</p>
                            <VValidate name="Anak Ke- " v-model="row.arankaabl_anak_ke"
                              :rules="toValidate(mrValidation.arankaabl_anak_ke)" />
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div class="row">
                      <div class="col-md-6">
                        <table class="table table-sm table-bordered mt-3">
                          <thead>
                            <tr>
                              <th class="bg-success" colspan="2">Biodata Ibu</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Nama Ibu</td>
                              <td>{{row.arpdo_ibu_nama||"-"}}</td>
                            </tr>
                            <tr>
                              <td>Tanggal Lahir</td>
                              <td>
                                <span v-if="row.arpdo_ibu_dob">
                                  {{row.arpdo_ibu_dob | moment("DD MMMM YYYY")}}
                                </span>
                                <span v-else> - </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Umur</td>
                              <td>
                                <span v-if="row.arpdo_ibu_dob">
                                  {{ row.arpdo_ibu_dob | moment("from", "now", true) }}
                                </span>
                                <span v-else> - </span>
                              </td>
                              
                            </tr>
                            <tr>
                              <td>Suku/bangsa </td>
                              <td>{{row.ibu_suku||"-"}}</td>
                            </tr>
                            <tr>
                              <td>Agama </td>
                              <td>{{row.ibu_agama||"-"}}
                              <span v-if="row.ibu_agama == 'Lain-lain'">: {{row.arpdo_ibu_agama_text||"-"}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Pendidikan </td>
                              <td>{{row.ibu_pendidikan||"-"}}
                              <span v-if="row.ibu_pendidikan == 'Lain-lain'">: {{row.arpdo_ibu_pendidikan_text||"-"}}</span>
                              </td>
                              
                            </tr>
                            <tr>
                              <td>Pekerjaan</td>
                              <td>{{row.ibu_pekerjaan||"-"}}
                              <span v-if="row.ibu_pekerjaan == 'Lain-lain'">: {{row.arpdo_ibu_pekerjaan_text||"-"}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Alamat </td>
                              <td>{{row.arpdo_ibu_alamat_ktp||"-"}}</td>
                            </tr>
                            <tr>
                              <td>Status Perkawinan </td>
                              <td>{{getConfigDynamic(Config.mr.StatusPernikahan,row.arpdo_ibu_status_perkawinan)||"-"}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-6">
                        <table class="table table-sm table-bordered mt-3">
                          <thead>
                            <tr>
                              <th class="bg-primary" colspan="2">Biodata Ayah</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Nama Ayah</td>
                              <td>{{row.arpdo_ayah_nama||"-"}}</td>
                            </tr>
                            <tr>
                              <td>Tanggal Lahir</td>
                              <td>
                                <span v-if="row.arpdo_ayah_dob">
                                  {{row.arpdo_ayah_dob | moment("DD MMMM YYYY")}}
                                </span>
                                <span v-else> - </span>
                              </td>
                            </tr>
                            <tr>
                              <td>Umur</td>
                              <td>
                                <span v-if="row.arpdo_ayah_dob">
                                  {{ row.arpdo_ayah_dob | moment("from", "now", true) }}
                                </span>
                                <span v-else> - </span>
                              </td>
                              
                            </tr>
                            <tr>
                              <td>Suku/bangsa </td>
                              <td>{{row.ayah_suku||"-"}}</td>
                            </tr>
                            <tr>
                              <td>Agama </td>
                              <td>{{row.ayah_agama||"-"}}
                              <span v-if="row.ayah_agama == 'Lain-lain'">: {{row.arpdo_ayah_agama_text||"-"}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Pendidikan </td>
                              <td>{{row.ayah_pendidikan||"-"}}
                              <span v-if="row.ayah_pendidikan == 'Lain-lain'">: {{row.arpdo_ayah_pendidikan_text||"-"}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Pekerjaan</td>
                              <td>{{row.ayah_pekerjaan||"-"}}
                              <span v-if="row.ayah_pekerjaan == 'Lain-lain'">: {{row.arpdo_ayah_pekerjaan_text||"-"}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Alamat </td>
                              <td>{{row.arpdo_ayah_alamat_ktp||"-"}}</td>
                            </tr>
                            <tr>
                              <td>Status Perkawinan </td>
                              <td>{{getConfigDynamic(Config.mr.StatusPernikahan,row.arpdo_ayah_status_perkawinan)||"-"}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card mt-3 mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Cap Ibu & Bayi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="">Cap Ibu Jari Tangan Ibu Bayi <strong class="text-danger">*</strong></label>
                          <Uploader v-model="row.arankaabl_cap_tangan_bayi" isDocument type="docimage" />
                          <VValidate name="Cap Ibu Jari Tangan Ibu Bayi" v-model="row.arankaabl_cap_tangan_bayi"
                            :rules="toValidate(mrValidation.arankaabl_cap_tangan_bayi)" />
                        </div>
                        <div class="col-md-3">
                          <label for="">Cap Kaki Kiri & Kanan Bayi </label>
                          <Uploader v-model="row.arankaabl_cap_kaki_bayi" isDocument type="docimage" />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="wrap_line">
                  <h3><span>Riwayat Kesehatan</span></h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Riwayat Ibu</label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline pl-0">
                            <b-form-radio-group :options="Config.mr.mConfigRiwayat"
                              v-model="row.arankaabl_is_riwayat_penyakit" />
                            <div class="ml-2 col-md-4" v-if="row.arankaabl_is_riwayat_penyakit == 'Y'">
                              <b-form-input :formatter="normalText" v-model="row.arankaabl_is_riwayat_penyakit_name"
                                type="text" class="form-control" />
                              <VValidate name="Riwayat Ibu" v-model="row.arankaabl_is_riwayat_penyakit_name"
                                :rules="{required: 1, min:3, max:128}" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Riwayat Keluarga</label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline pl-0">
                            <b-form-radio-group :options="Config.mr.mConfigRiwayat"
                              v-model="row.arankaabl_is_riwayat_penyakit_keluarga" />
                            <div class="ml-2 col-md-4" v-if="row.arankaabl_is_riwayat_penyakit_keluarga == 'Y'">
                              <b-form-input :formatter="normalText"
                                v-model="row.arankaabl_is_riwayat_penyakit_keluarga_name" type="text"
                                class="form-control" />
                              <VValidate name="Riwayat Keluarga"
                                v-model="row.arankaabl_is_riwayat_penyakit_keluarga_name"
                                :rules="{required: 1, min:3, max:128}" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="">Apakah ibu mengalami hepatitis <span class="text-danger">*</span> </label>
                        <div>
                          <div class="custom-control custom-radio custom-control-inline pl-0">
                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                              v-model="row.arankaabl_is_ibu_hepatitis" />
                          </div>
                        </div>
                        <VValidate name="Mengalami Hepatitis" v-model="row.arankaabl_is_ibu_hepatitis" rules="required" />
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3><span>Riwayat Intranatal</span></h3>
                    <div class="row gx-4">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Jenis Persalinan</label>
                          <div>
                            <div class="custom-control custom-radio custom-control-inline pl-0">
                              <b-form-radio-group class="radio-inline custom-block-2" 
                                :options="Config.mr.mRiwayatIntraNatalJenisPersalinan"
                                v-model="row.arankaabl_jenis_persalinan" />
                              <div class="mt-5 col-md-8" v-if="row.arankaabl_jenis_persalinan === 'Lain-lain'">
                                <b-form-input :formatter="normalText" v-model="row.arankaabl_jenis_persalinan_lainnya"
                                  type="text" class="form-control" />
                                <VValidate name="Jenis Persalinan" v-model="row.arankaabl_jenis_persalinan_lainnya"
                                  :rules="{required: 1, min:3, max:128}" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Penolong</label>
                          <div>
                            <b-form-checkbox-group
                                class="checkbox-inline"
                                v-model="row.arankaabl_penolong"
                                :options="Config.mr.mRiwayatIntraNatalPenolong"
                            ></b-form-checkbox-group>

                            <div class="col-md-4" v-if="(row.arankaabl_penolong||[]).indexOf('Lain-lain') != -1">
                              <b-form-input :formatter="normalText" v-model="row.arankaabl_penolong_lainnya"
                                type="text" class="form-control" />
                              <VValidate name="Penolong" v-model="row.arankaabl_penolong_lainnya"
                                :rules="{required: 1, min:3, max:128}" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <h3><span>Lama Persalinan</span></h3>
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Kala I</label>
                              <b-form-input :formatter="normalText" v-model="row.arankaabl_kala_1" type="text"
                                class="form-control" />
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Kala II</label>
                              <b-form-input :formatter="normalText" v-model="row.arankaabl_kala_2" type="text"
                                class="form-control" />
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Komplikasi Persalinan</label>
                              <b-form-input :formatter="normalText" v-model="row.arankaabl_komplikasi_persalinan"
                                type="text" class="form-control" />
                            </div>
                          </div>
                          
                          <div class="col-md-2">
                            <div class="form-group">
                              <label for="bayiWarnaKulit">Ketuban</label>
                              <b-form-input :formatter="normalText" v-model="row.arankaabl_ketuban"
                                type="text" class="form-control" />
                            </div>
                          </div>

                          
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Lama Persalinan</label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankaabl_jam" type="text"
                                  class="form-control" placeholder="e.g. 1" />
                                <div class="input-group-append"><span class="input-group-text">Jam</span></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <label style="opacity: 0;">Lama Persalinan</label>
                              <div class="input-group">
                                <b-form-input :formatter="number" v-model="row.arankaabl_menit" type="text"
                                  class="form-control" placeholder="e.g. 1" />
                                <div class="input-group-append"><span class="input-group-text">Menit</span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <h3><span>Kondisi Bayi</span></h3>
                    <div class="card mb-2">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Status Praesens</h6>
                      </div>
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-md-2">
                            <div class="form-group">
                              <label class="" for="">Tanggal Lahir</label>
                              <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                                </div>
                                <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.arankaabl_dob">
                                </datepicker>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="" for="">Jam Lahir</label>
                              <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                                </div>
                                <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arankaabl_tob">
                                </vue-timepicker>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                              <div class="mb-2">
                                <label for="keadaanUmum">Keadaan Umum</label>
                                <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_keadaan_umum"
                                  :options="Config.mr.mRanapBBLKeadaanUmum" label="text" :clearable="true"
                                  :reduce="v=>v.value"></v-select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="JenisKelamin">Jenis Kelamin </label>
                              <v-select @input="onInput($event, 'jenis-kelamin')" @close="onBlur($event, 'jenis-kelamin')" @open="onFocus($event, 'jenis-kelamin')" :disabled="disabled['jenis-kelamin']" placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_jenis_kelamin"
                                :options="Config.mr.genderText" label="text" :clearable="true" :reduce="v=>v.value">
                              </v-select>
                              <p class='text-muted' v-if="disabled['jenis-kelamin']">User lain sedang mengisi...</p>
                            </div>
                          </div>
                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label for="bayiBeratBadan">Berat Badan</label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'berat-badan')" @blur="onBlur($event, 'berat-badan')" @focus="onFocus($event, 'berat-badan')" :disabled="disabled['berat-badan']" :formatter="number" v-model="row.arankaabl_bbl" type="text"
                                  class="form-control" placeholder="e.g. 3.97" />
                                <div class="input-group-append"><span class="input-group-text">gram</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['berat-badan']">User lain sedang mengisi...</p>
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label for="bayiPanjang">Panjang Badan</label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'panjang-badan')" @blur="onBlur($event, 'panjang-badan')" @focus="onFocus($event, 'panjang-badan')" :disabled="disabled['panjang-badan']" :formatter="number" v-model="row.arankaabl_panjang" type="text"
                                  class="form-control" placeholder="e.g. 75" />
                                <div class="input-group-append"><span class="input-group-text">cm</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['panjang-badan']">User lain sedang mengisi...</p>
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label for="LingkarKepala">Lingkar Kepala</label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'lingkar-kepala')" @blur="onBlur($event, 'lingkar-kepala')" @focus="onFocus($event, 'lingkar-kepala')" :disabled="disabled['lingkar-kepala']" :formatter="number" v-model="row.arankaabl_lingkar_kepala" type="text"
                                  class="form-control" placeholder="e.g. 75" />
                                <div class="input-group-append"><span class="input-group-text">cm</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['lingkar-kepala']">User lain sedang mengisi...</p>
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label for="bayiPanjang">Lingkar Dada </label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'lingkar-dada')" @blur="onBlur($event, 'lingkar-dada')" @focus="onFocus($event, 'lingkar-dada')" :disabled="disabled['lingkar-dada']" :formatter="number" v-model="row.arankaabl_lingkar_dada" type="text"
                                  class="form-control" placeholder="e.g. 75" />
                                <div class="input-group-append"><span class="input-group-text">cm</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['lingkar-dada']">User lain sedang mengisi...</p>
                            </div>
                          </div>


                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>Nadi</label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'nadi')" @blur="onBlur($event, 'nadi')" @focus="onFocus($event, 'nadi')" :disabled="disabled['nadi']" :formatter="number" v-model="row.arankaabl_nadi" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['nadi']">User lain sedang mengisi...</p>
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>RR</label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'rr')" @blur="onBlur($event, 'rr')" @focus="onFocus($event, 'rr')" :disabled="disabled['rr']" :formatter="number" v-model="row.arankaabl_rr" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['rr']">User lain sedang mengisi...</p>
                            </div>
                          </div>

                          <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                              <label>Suhu Badan</label>
                              <div class="input-group">
                                <b-form-input @input="onInput($event, 'suhu-badan')" @blur="onBlur($event, 'suhu-badan')" @focus="onFocus($event, 'suhu-badan')" :disabled="disabled['suhu-badan']" :formatter="number" v-model="row.arankaabl_suhu" type="text"
                                  class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">Celcius</span></div>
                              </div>
                              <p class='text-muted' v-if="disabled['suhu-badan']">User lain sedang mengisi...</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">APGAR</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-12">
                              <table class="table table-bordered table-hover pain-scale-table">
                                <thead>
                                  <tr>
                                    <th>Kriteria</th>
                                    <th style="width:27%;">1 Menit</th>
                                    <th style="width:27%;">5 Menit</th>
                                    <th style="width:27%;">10 Menit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th class="table-warning">Denyut Jantung</th>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --"
                                        v-model="row.arankaabl_denyut_jantung_1" :options="Config.mr.mZerotoTwo"
                                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                      
                                      <VValidate 
                                        :name="'Denyut Jantung 1 menit'"
                                        v-model="row.arankaabl_denyut_jantung_1" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --"
                                        v-model="row.arankaabl_denyut_jantung_5" :options="Config.mr.mZerotoTwo"
                                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                                      <VValidate 
                                        :name="'Denyut Jantung 5 menit'"
                                        v-model="row.arankaabl_denyut_jantung_5" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --"
                                        v-model="row.arankaabl_denyut_jantung_10" :options="Config.mr.mZerotoTwo"
                                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        
                                      <VValidate 
                                        :name="'Denyut Jantung 10 menit'"
                                        v-model="row.arankaabl_denyut_jantung_10" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-warning">Nafas</th>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_nafas_1"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Nafas 1 menit'"
                                        v-model="row.arankaabl_nafas_1" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_nafas_5"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Nafas 5 menit'"
                                        v-model="row.arankaabl_nafas_5" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_nafas_10"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Nafas 10 menit'"
                                        v-model="row.arankaabl_nafas_10" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-warning">Tonus Otot</th>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_tonus_otot_1"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Tonus Otot 1 menit'"
                                        v-model="row.arankaabl_tonus_otot_1" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_tonus_otot_5"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Tonus Otot 5 menit'"
                                        v-model="row.arankaabl_tonus_otot_5" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --"
                                        v-model="row.arankaabl_tonus_otot_10" :options="Config.mr.mZerotoTwo"
                                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Tonus Otot 10 menit'"
                                        v-model="row.arankaabl_tonus_otot_10" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-warning">Reflek</th>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_reflek_1"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Reflek 1 menit'"
                                        v-model="row.arankaabl_reflek_1" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_reflek_5"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Reflek 5 menit'"
                                        v-model="row.arankaabl_reflek_5" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_reflek_10"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Reflek 10 menit'"
                                        v-model="row.arankaabl_reflek_10" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th class="table-warning">Warna Kulit</th>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_wk_1"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Warna Kulit 1 menit'"
                                        v-model="row.arankaabl_wk_1" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_wk_5"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Warna Kulit 5 menit'"
                                        v-model="row.arankaabl_wk_5" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                    <td>
                                      <v-select placeholder="-- Plih Salah Satu --" v-model="row.arankaabl_wk_10"
                                        :options="Config.mr.mZerotoTwo" label="text" :clearable="true"
                                        :reduce="v=>v.value"></v-select>
                                      <VValidate 
                                        :name="'Warna Kulit 10 menit'"
                                        v-model="row.arankaabl_wk_10" 
                                        :rules="{required : 1}"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                                <tfoot>
                                  <tr class="table-info">
                                    <td class="text-info-700">
                                      <span class="text-uppercase">TOTAl</span>
                                    </td>
                                    <td class="text-info-700">
                                      <span class="text-uppercase">{{row.arankaabl_total_1_menit||0}}</span>
                                    </td>
                                    <td class="text-info-700">
                                      <span class="text-uppercase">{{row.arankaabl_total_5_menit||0}}</span>
                                    </td>
                                    <td class="text-info-700">
                                      <span class="text-uppercase">{{row.arankaabl_total_10_menit||0}}</span>
                                    </td>
                                    <!-- Penilaian APGAR
                                            7 - 11 = Risiko Rendah
                                            >= 12 = Risiko Tinggi
                                            -->
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--<div class="card mb-2">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                                </div>
                                <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuKesan">Kesan</label>
                                        <input type="text" class="form-control" id="kuKesan">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuPergerakan">Pergerakan</label>
                                        <input type="text" class="form-control" id="kuPergerakan">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuWarna">Warna</label>
                                        <input type="text" class="form-control" id="kuWarna">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuSuara">Suara</label>
                                        <input type="text" class="form-control" id="kuSuara">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuPernafasan">Pernafasan</label>
                                        <input type="text" class="form-control" id="kuPernafasan">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuTonus">Tonus</label>
                                        <input type="text" class="form-control" id="kuTonus">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuSikap">Sikap</label>
                                        <input type="text" class="form-control" id="kuSikap">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuReflekMoro">Reflek Moro</label>
                                        <input type="text" class="form-control" id="kuReflekMoro">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuMemegang">Memegang</label>
                                        <input type="text" class="form-control" id="kuMemegang">
                                    </div>
                                    </div>
                                    <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                        <label for="kuMengenyut">Mengenyut</label>
                                        <input type="text" class="form-control" id="kuMengenyut">
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>-->
                  </div>

                  <div class="wrap_line">
                    <h3><span>Pemeriksaan Khusus</span></h3>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card mb-2">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Inpeksi</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row g-0">
                              <div class="col-md-6">
                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="kepalaOpen = !kepalaOpen" :class="kepalaOpen ? null : 'collapsed'"
                                    :aria-expanded="kepalaOpen ? 'true' : 'false'" aria-controls="kepalaOpen">
                                    <div class="font-weight-semibold">Kepala</div>
                                    <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'kepalaOpen'" v-model="kepalaOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_inpeksi_kepala" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_inpeksi_kepala == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_inpeksi_kepala_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="wajahOpen = !wajahOpen" :class="wajahOpen ? null : 'collapsed'"
                                    :aria-expanded="wajahOpen ? 'true' : 'false'" aria-controls="wajahOpen">
                                    <div class="font-weight-semibold">Wajah</div>
                                    <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'wajahOpen'" v-model="wajahOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_wajah" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_wajah == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_wajah_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="leherOpen = !leherOpen" :class="leherOpen ? null : 'collapsed'"
                                    :aria-expanded="leherOpen ? 'true' : 'false'" aria-controls="leherOpen">
                                    <div class="font-weight-semibold">Leher</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'leherOpen'" v-model="leherOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_leher" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_leher == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_leher_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>


                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="dadaOpen = !dadaOpen" :class="dadaOpen ? null : 'collapsed'"
                                    :aria-expanded="dadaOpen ? 'true' : 'false'" aria-controls="dadaOpen">
                                    <div class="font-weight-semibold">Dada</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'dadaOpen'" v-model="dadaOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_dada" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_dada == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_dada_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="abdomenOpen = !abdomenOpen" :class="abdomenOpen ? null : 'collapsed'"
                                    :aria-expanded="abdomenOpen ? 'true' : 'false'" aria-controls="abdomenOpen">
                                    <div class="font-weight-semibold">Abdomen</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>
                                  

                                  <b-collapse :id="'abdomenOpen'" v-model="abdomenOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_abdomen" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_abdomen == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_abdomen_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="EkstremitasOpen = !EkstremitasOpen" :class="EkstremitasOpen ? null : 'collapsed'"
                                    :aria-expanded="EkstremitasOpen ? 'true' : 'false'" aria-controls="EkstremitasOpen">
                                    <div class="font-weight-semibold">Ekstremitas</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>
                                  

                                  <b-collapse :id="'EkstremitasOpen'" v-model="EkstremitasOpen">
                                    <div class="gen-part-body mt-2">
                                      <div><label>Atas</label></div>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_ekstremitas_atas" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_ekstremitas_atas == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_ekstremitas_atas_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>

                                      <div><label>Bawah</label></div>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.normalTidakNormalOptTP"
                                          v-model="row.arankaabl_ekstremitas_bawah" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_ekstremitas_bawah == 'TN'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_ekstremitas_bawah_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="anusOpen = !anusOpen" :class="anusOpen ? null : 'collapsed'"
                                    :aria-expanded="anusOpen ? 'true' : 'false'" aria-controls="anusOpen">
                                    <div class="font-weight-semibold">Anus</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>
                                  
                                  <b-collapse :id="'anusOpen'" v-model="anusOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.mAnusLubang"
                                          v-model="row.arankaabl_anus" />
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="eliminasiOpen = !eliminasiOpen" :class="eliminasiOpen ? null : 'collapsed'"
                                    :aria-expanded="eliminasiOpen ? 'true' : 'false'" aria-controls="eliminasiOpen">
                                    <div class="font-weight-semibold">Eliminasi</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'eliminasiOpen'" v-model="eliminasiOpen">
                                    <div class="gen-part-body mt-2">
                                      <div><label>Miksi</label></div>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.mSudahBelum"
                                          v-model="row.arankaabl_el_miksi" />
                                      </div>

                                      <div class="mt-2"><label>Meconium</label></div>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.mSudahBelum"
                                          v-model="row.arankaabl_el_meconium" />
                                      </div>
                                      
                                      <textarea v-model="row.arankaabl_el_meconium_text" class="form-control mt-2"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="terapiOpen = !terapiOpen" :class="terapiOpen ? null : 'collapsed'"
                                    :aria-expanded="terapiOpen ? 'true' : 'false'" aria-controls="terapiOpen">
                                    <div class="font-weight-semibold">Terapi</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'terapiOpen'" v-model="terapiOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-checkbox-group :options="Config.mr.mTerapiBBL"
                                          v-model="row.arankaabl_terapi" />
                                      </div>
                                      <div class="input-group mb-3 mt-2">
                                        <datepicker input-class="form-control transparent"
                                          placeholder="Pilih Tanggal" class="my-datepicker"
                                          calendar-class="my-datepicker_calendar" v-model="row.arankaabl_terapi_date">
                                        </datepicker>
                                        <div class="input-group-append calendar-group">
                                          <span class="input-group-text" id="basic-addon2"><i
                                              class="icon-calendar"></i></span>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>

                                <div class="gen-part">
                                  <div class="d-flex align-items-center justify-content-between gen-part-head"
                                    @click="imdOpen = !imdOpen" :class="imdOpen ? null : 'collapsed'"
                                    :aria-expanded="imdOpen ? 'true' : 'false'" aria-controls="imdOpen">
                                    <div class="font-weight-semibold">IMD (Inisiasi Menyusui Dini)</div>
                                      <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="'imdOpen'" v-model="imdOpen">
                                    <div class="gen-part-body mt-2">
                                      <div>
                                        <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                          v-model="row.arankaabl_imd" />
                                      </div>
                                      <div class="gen-input mt-3" v-if="row.arankaabl_imd != 'Y'">
                                        <div class="form-row">
                                          <div class="form-group col-md-12">
                                            <textarea v-model="row.arankaabl_imd_text" class="form-control"
                                              placeholder="Tuliskan" rows="2"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-collapse>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="wrap_line">
                    <div class="card-header bg-info">
                      <h5 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h5>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                        <label for="kidPainScale">Skala Nyeri</label>
                        <table class="table table-bordered pain-scale-table">
                            <thead>
                            <tr>
                                <th>Pengkajian</th>
                                <th>Nilai 0</th>
                                <th>Nilai 1</th>
                                <th>Nilai 2</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(v,k) in (row.arankaabl_skala_nyeri_nips||[])" :key="k">
                                <th class="table-warning text-center"><img :src="assetLocal(v.img)" style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>{{v.name||"-"}}</th>
                                <td v-for="(vc,kc) in v.configVal" :key="kc">
                                <div class="form-check form-check-inline align-middle">
                                <label class="form-check-label" :for="v.name+k+kc">
                                    <b-form-radio @input="inputData('skala_nyeri_nips')" type="radio" :value="vc.value" v-model="row.arankaabl_skala_nyeri_nips[k]['value']" :id="v.name+k+kc" class="form-check-input-styled" />{{vc.text}}
                                </label>
                                </div>
                                <br/>
                                <VValidate 
                                    :name="v.name"
                                    v-model="row.arankaabl_skala_nyeri_nips[k]['value']" 
                                    :rules="{required : 1}"
                                />
                                
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr class="table-info">
                                <td colspan="4" class="text-info-700">
                                <span>SKALA: {{hitungSkala}} </span>
                                
                                <span v-if="hitungSkala < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                <span v-else-if="hitungSkala >= 3 && hitungSkala < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        </div>
                        
                        <span class="text-warning d-none" id="skala_nyeri_nips-typing">User Lain Sedang Mengisi ...</span>                                                                                   
                    </div>  
                  </div>

                  <div class="wrap_line">
                    <div class="card">
                      <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Pengkajian Risiko Jatuh Neonatus</h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">INTERVENSI</h6>
                              </div>
                              <div class="card-body">
                                <div class="form-group mt-3">
                                  <div>
                                    <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="row.arankaabl_neo_intervensi"
                                        :options="mNeoIntervensi"
                                    ></b-form-checkbox-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">EDUKASI YANG DIBERIKAN</h6>
                              </div>
                              <div class="card-body">
                                <div class="form-group mt-3">
                                  <div class="">
                                    <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="row.arankaabl_neo_edukasi"
                                        :options="mNeoEdukasi"
                                    ></b-form-checkbox-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">SASARAN EDUKASI</h6>
                              </div>
                              <div class="card-body">
                                <div class="form-group mt-3">
                                  <div class="">
                                    <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="row.arankaabl_neo_sasaran"
                                        :options="mNeoSasaran"
                                    ></b-form-checkbox-group>
                                  </div>
                                  <div v-if="(row.arankaabl_neo_sasaran||[]).indexOf('Lainnya') != -1">
                                    <b-form-input v-model="row.arankaabl_neo_sasaran_lainnya" type="text"
                                    class="form-control" />
                                        
                                    <VValidate 
                                        :name="'Lainnya'"
                                        v-model="row.arankaabl_neo_sasaran_lainnya" 
                                        :rules="{required : 1, min: 3, max:128}"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">EVALUASI</h6>
                              </div>
                              <div class="card-body">
                                <div class="form-group mt-3">
                                  <div class="">
                                    <b-form-checkbox-group
                                        class="checkbox-inline"
                                        v-model="row.arankaabl_neo_evaluasi"
                                        :options="mNeoEvaluasi"
                                    ></b-form-checkbox-group>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i
                    class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'
  import PatientInfo from '@/components/Ranap/PatientInfo.vue'
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'


  export default {
    extends: GlobalVue,
    components:{ 
        Datepicker,
        PatientInfo,
        VueTimepicker
    },
    data() {
      return {
        rowReg: {},

        kepalaOpen: false,
        EkstremitasOpen: false,
        wajahOpen: false,
        anusOpen: false,
        leherOpen: false,

        eliminasiOpen: false,
        dadaOpen: false,
        terapiOpen: false,
        abdomenOpen: false,
        imdOpen: false,


        mNeoIntervensi: [],
        mNeoEdukasi: [],
        mNeoSasaran: [],
        mNeoEvaluasi: [],
        
        patientData: {},
        loading: {
          patientInfo: false,
        },
        
        disabled: {
          'anak-ke': false,
          'jenis-kelamin': false,
          'berat-badan': false,
          'panjang-badan': false,
          'lingkar-kepala': false,
          'lingkar-dada': false,
          'nadi': false,
          'rr': false,
          'suhu-badan': false
        }
      }
    },
    computed: {
      total1Menit() {
        let total = 0
        total = (this.row.arankaabl_denyut_jantung_1 || 0) + (this.row.arankaabl_nafas_1 || 0) + (this.row
          .arankaabl_tonus_otot_1 || 0) + (this.row.arankaabl_reflek_1 || 0) + (this.row.arankaabl_wk_1 || 0)
        return total
      },
      total5Menit() {
        let total = 0
        total = (this.row.arankaabl_denyut_jantung_5 || 0) + (this.row.arankaabl_nafas_5 || 0) + (this.row
          .arankaabl_tonus_otot_5 || 0) + (this.row.arankaabl_reflek_5 || 0) + (this.row.arankaabl_wk_5 || 0)
        return total
      },
      total10Menit() {
        let total = 0
        total = (this.row.arankaabl_denyut_jantung_10 || 0) + (this.row.arankaabl_nafas_10 || 0) + (this.row
          .arankaabl_tonus_otot_10 || 0) + (this.row.arankaabl_reflek_10 || 0) + (this.row.arankaabl_wk_10 || 0)
        return total
      },
      
      hitungSkala(){
        let value = 0
        for(let i = 0; i < (this.row.arankaabl_skala_nyeri_nips||[]).length; i++){
            value += this.row.arankaabl_skala_nyeri_nips[i]['value']
        }  
        return value
      },  
    },
    methods: {
      onFocus(v, field){
        const regId = this.$route.query.regId
        const payload = {
          to: 'kajian-awal-bbl',
          type: 'set-disable',
          field: field,
          regId: regId
        }
        this.$socket.emit('collaborate', payload)
      },
      onBlur(v, field){
        const regId = this.$route.query.regId
        const payload = {
          to: 'kajian-awal-bbl',
          type: 'set-enable',
          field: field,
          regId: regId
        }
        this.$socket.emit('collaborate', payload)
      },
      onInput: _.debounce(function(v, field){
        const regId = this.$route.query.regId

        console.log(v)
          const payload = {
            to: 'kajian-awal-bbl',
            type: 'set-value',
            field: field,
            regId: regId,
            value: v
          }

          this.$socket.emit('collaborate', payload)
      }, 500),
      back() {
        this.$router.back()
      },
      toValidate(val) {
        return {
          ...val
        }
      },
      inputData(field) {
        console.log(field)
        console.log("for realtime")
      },
      apiGet(params = {}, page = 1) {
        if (!this.pageSlug || !this.$route.query.regId) {
          this.$router.push({
            name: 'Dashboard'
          }).catch(() => {})
        }

        if (this.pageSlug || this.$route.name == 'Dashboard') this.loadingOverlay = true
        this.data.data = false
        let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        let url = this.$route.params.rmNo ? this.modulePage + '/' + this.$route.params.pageSlug + '/' + this
          .$route.params.rmNo : this.modulePage + '/' + this.$route.params.pageSlug

        Gen.apiRest(
          "/get/" + url, {
            params: Object.assign({
              page: page
            }, paramsQuery, params.query || {})
          }
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.pageNow = page
          this.last_page = this.data.last_page

          if (!this.isFound) {
            this.$router.push({
              name: 'Dashboard'
            }).catch(() => {})
          }
        }).catch(() => {
          this.loadingOverlay = false
        })

        // master
        if (this.$route.params.rmNo) {
          Gen.apiRest(
            "/get/" + url + '?master=1', {
              params: Object.assign({
                page: page
              }, paramsQuery, params.query || {})
            }
          ).then(res => {
            _.forEach(res.data, (v, k) => {
              this.$set(this, k, v)
            })
          })
        }

      },
      getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
          "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.query.regId
            }
          },
          "POST"
        ).then(resp => {
          this.$set(this, 'patientData', resp.data.data)

          this.$set(this.loading, 'patientInfo', false)
        })
      },

      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
          let index = (master || []).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },

      autoSave: _.debounce(function (data) {
        data.type = 'auto-save'
        data.aranrm_last_step = this.row.aranrm_last_step

        if (!data.isEdit) {
          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: data
            },
            "POST"
          )
        }
      }, 1000),

      doSubmit() {

        if (this.row.aranrm_is_active == 'N') {
          return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          })
        }

        this.$refs['VFormBBL'].validate().then(success => {
          console.log(success)
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll(
                    ".label_error:not([style='display: none;'])")
                  console.log(el)
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }

                  if (inv.length) document.getElementById(inv[0])
                    .scrollIntoView({
                      behavior: 'smooth',
                      block: 'center'
                    })
                }, 500)
              }
            })
          }
          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.row
                data.type = 'submit-data'

                this.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + this.modulePage, {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false

                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    this.$router.push({
                      name: 'RanapPasienPerawatan'
                    }).catch(() => {})
                  })
                })
              }
            })
          }
        })
      }
    },
    mounted() {
      this.apiGet()

      this.sockets.subscribe('collaborate_kajian-awal-bbl', data => {
          if(data.regId != this.$route.query.regId) {
              return
          }
          
          if(data.type == 'set-disable'){
              this.$set(this.disabled, data.field, true)
          }
          else if(data.type == 'set-enable'){
              this.$set(this.disabled, data.field, false)
          }

          else if(data.type == 'set-value'){
              if(data.field == 'anak-ke'){
                  this.$set(this.row, 'arankaabl_anak_ke', data.value)
              }
              if(data.field == 'jenis-kelamin'){
                  this.$set(this.row, 'arankaabl_jenis_kelamin', data.value)
              }
              if(data.field == 'berat-badan'){
                  this.$set(this.row, 'arankaabl_bbl', data.value)
              }
              if(data.field == 'panjang-badan'){
                  this.$set(this.row, 'arankaabl_panjang', data.value)
              }
              if(data.field == 'lingkar-kepala'){
                  this.$set(this.row, 'arankaabl_lingkar_kepala', data.value)
              }
              if(data.field == 'lingkar-dada'){
                  this.$set(this.row, 'arankaabl_lingkar_dada', data.value)
              }
              if(data.field == 'rr'){
                  this.$set(this.row, 'arankaabl_rr', data.value)
              }
              if(data.field == 'nadi'){
                  this.$set(this.row, 'arankaabl_nadi', data.value)
              }
              if(data.field == 'suhu-badan'){
                  this.$set(this.row, 'arankaabl_suhu', data.value)
              }
          }

          console.log(this.disabled)
          console.log(data)
      })

      this.getPatientInfo()
    },

    destroyed(){
        this.sockets.unsubscribe('collaborate_kajian-awal-bbl')
    },

    watch: {
      row: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },

      total1Menit(v) {
        this.row.arankaabl_total_1_menit = v
      },
      total5Menit(v) {
        this.row.arankaabl_total_5_menit = v
      },
      total10Menit(v) {
        this.row.arankaabl_total_10_menit = v
      },
      
      hitungSkala(v){
        setTimeout(()=>{
          this.row.arankaabl_skala_nyeri_nips_value = v
        },250)
      },

    },
  }
</script>